import camelize from 'camelize';
import { parseDate, parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiSecureGet, apiSecurePost, apiSecurePut } from 'api/ApiService';

export const getReports = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/reports`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportsTransform(data);
};

export const getReport = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const reportId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/reports/${reportId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportTransform(data);
};

export const getReportPdf = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const reportId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/reports/${reportId}.pdf`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.blob();
  return data
};

export const getJuniorClubRoundPdf = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const roundId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/summaries.pdf?summary_type=junior_round&round_id=${roundId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.blob();
  return data
};

export const getSeniorClubRoundPdf = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const roundId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/summaries.pdf?summary_type=senior_round&round_id=${roundId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.blob();
  return data
};

export const getJuniorClubSeasonPdf = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const fromRoundId = queryKey[3];
  const toRoundId = queryKey[4];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/summaries.pdf?summary_type=junior_season&from_round_id=${fromRoundId}&to_round_id=${toRoundId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.blob();
  return data
};

export const getSeniorClubSeasonPdf = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const fromRoundId = queryKey[3];
  const toRoundId = queryKey[4];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/summaries.pdf?summary_type=senior_season&from_round_id=${fromRoundId}&to_round_id=${toRoundId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.blob();
  return data
};

export const getJuniorClubAllSeasonsPdf = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const fromSeasonId = queryKey[3];
  const toSeasonId = queryKey[4];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/summaries.pdf?summary_type=junior_all_seasons&from_season_id=${fromSeasonId}&to_season_id=${toSeasonId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.blob();
  return data
};


export const getReportsExport = async ({club_id}) => {
  return apiSecureGet(`/v1/clubs/${club_id}/reports.csv`)
    .then((response) => response.text())
    .catch((error) => console.log(error));
};

// Not yet used. Still using getReportsExport
export const getReportsCSV = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/reports.csv`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.text();
  return data
};

export const createReport = async ({ clubId, report }) => {
  const response = await apiSecurePost(`/v1/clubs/${clubId}/reports`, {
    report
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportTransform(data);
};

export const updateReport = async ({ clubId, reportId, report }) => {
  const response = await apiSecurePut(`/v1/clubs/${clubId}/reports/${reportId}`, {
    report
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportTransform(data);
};

const transform = (result = {}) => {
  switch (result.status) {
    case 'pending_assessment':
      result.statusDescription = 'Pending assessment';
      result.statusShort = 'Pending assessment';
      break;
    case 'under_assessment':
      result.statusDescription = 'Under assessment';
      result.statusShort = 'Under assessment';
      break;
    case 'not_cleared_to_play':
      result.statusDescription = 'Not cleared to play';
      result.statusShort = 'Not cleared to play';
      break;
    case 'closed_cleared_to_play':
      result.statusDescription = 'Closed cleared to play';
      result.statusShort = 'Closed cleared to play';
      break;
    default:
      result.statusDescription = '';
      result.statusShort = '';
  }
  result.field_data.incidentOnShort = formattedShortDate(parseDate(result?.field_data?.incident_on));
  result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDate(parseDateTime(result.updated_at));
  return camelize(result);
};

export const reportTransform = (result = {}) => {
  return transform(result.data);
};

export const reportsTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
